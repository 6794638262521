/* CHAT */
.str-chat {
  --str-chat__font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    Helvetica Neue, sans-serif !important;
  --str-chat__own-message-bubble-color: #fff !important;
  --str-chat__own-message-reaction-color: #fff !important;
  --str-chat__own-message-bubble-background-color: #2d3748 !important;
  --str-chat__own-message-reaction-background-color: #2d3748 !important;
  --str-chat__message-bubble-color: #4a5568 !important;
  --str-chat__message-bubble-background-color: #edf2f7 !important;
  --str-chat__deleted-message-background-color: #edf2f7 !important;
  --str-chat__deleted-message-color: #4a5568 !important;
  --str-chat__avatar-background-color: #edf2f7 !important;
  --str-chat__avatar-color: #2d3748 !important;
  --str-chat__message-send-color: #2d3748 !important;
  ul,
  li {
    list-style: none !important;
  }
}

.str-chat__date-separator {
  justify-content: center;
  hr {
    display: none !important;
  }
  .str-chat__date-separator-date {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: normal;
    color: #4a5568;
    background-color: #edf2f7;
    border-radius: 24px;
    padding: 6px 12px;
  }
}

/* VIDEO */
/* VIDEO */
.str-video {
  --str-video__icon--blur: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJMYXllcl8xIiBkYXRhLW5hbWU9IkxheWVyIDEiIHZpZXdCb3g9IjAgMCAyNCAyNCIKICB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+CiAgPHBhdGgKICAgIGQ9Ik0xMCwyNEExMC4wNDgsMTAuMDQ4LDAsMCwxLDAsMTRDMCw4LjY2Myw2LjUsMS43NTEsNi43NzEsMS40NTlBNC4yODYsNC4yODYsMCwwLDEsMTAuMDA2LDBoMGE0LjMwNiw0LjMwNiwwLDAsMSwzLjI2NiwxLjVjMi43NzEsMy4wMjMsMTEsMTIuNDg2LDMuOCwxOS41NzNBOS45MzQsOS45MzQsMCwwLDEsMTAsMjRaTTIxLDZhMywzLDAsMCwxLDAtNkEzLDMsMCwwLDEsMjEsNlptMC00YTEsMSwwLDAsMCwwLDJBMSwxLDAsMCwwLDIxLDJaIiAvPgo8L3N2Zz4=");
  --str-video__icon--blur-off: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJMYXllcl8xIiBkYXRhLW5hbWU9IkxheWVyIDEiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggZD0ibTIzLjcwNywyMy43MDdjLS4xOTUuMTk1LS40NTEuMjkzLS43MDcuMjkzcy0uNTEyLS4wOTgtLjcwNy0uMjkzTC4yOTMsMS43MDdDLS4wOTgsMS4zMTYtLjA5OC42ODQuMjkzLjI5M1MxLjMxNi0uMDk4LDEuNzA3LjI5M2wzLjg5OSwzLjg5OWMxLjE3Mi0xLjI1MSwyLjM0OS0yLjMwOSwzLjE4LTMuMDE1LDEuODYyLTEuNTc4LDQuNTY1LTEuNTc4LDYuNDI2LDAsMi45MDgsMi40NjksNy43ODcsNy4yODYsNy43ODcsMTEuODIxLDAsMi4zMzctLjc0NCw0LjYwNy0yLjEwOSw2LjQ3OWwyLjgxNiwyLjgxNmMuMzkxLjM5MS4zOTEsMS4wMjMsMCwxLjQxNFpNNC4wODMsOC4xNzNjLS4yMjItLjIyMS0uNTM0LS4zMjUtLjg0NC0uMjgxcy0uNTgyLjIyOS0uNzM0LjUwM2MtMS4wMTMsMS44MTYtMS41MDUsMy4zMjMtMS41MDUsNC42MDUsMCwyLjkzOCwxLjE0NCw1LjcsMy4yMjIsNy43NzgsMi4wNzgsMi4wNzcsNC44NCwzLjIyMiw3Ljc3OCwzLjIyMiwxLjcxNSwwLDMuNDI3LS40MDYsNC45NS0xLjE3NS4yODctLjE0NS40ODctLjQxOC41MzgtLjczNS4wNS0uMzE3LS4wNTUtLjY0LS4yODMtLjg2Nkw0LjA4Myw4LjE3M1oiLz4KPC9zdmc+");
}

.str-video {
  background-color: #171923;
  color: #ffffff;
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
}
.str-video__speaker-layout__spotlight .str-video__call-controls__button {
  z-index: 48;
}

.str-video__speaker-layout__spotlight .str-video__menu-container {
  z-index: 48;
}

.str-video__composite-button__button[data-testid="recording-start-button"],
.str-video__composite-button[title="Reactions"] {
  display: none !important;
}

button[data-testid="screen-share-start-button"] {
  display: none !important;
}

@media (max-width: 600px) {
  .str-video__call-controls {
    justify-content: flex-start !important;
    padding-left: 10px !important;
  }
}

/* VIDEO CALL */
.str-video__speaker-layout {
  .rd__framer-participant-view-wrapper {
    position: absolute;
    width: 200px;
    bottom: 0.875rem;
    right: 0.875rem;
    z-index: 1;

    .str-video__video-placeholder {
      background-color: var(--str-video__base-color6);
    }

    &:hover {
      cursor: move;
    }
  }
}

.str-video__livestream-layout__wrapper {
  .str-video__livestream-layout__overlay {
    .str-video__livestream-layout__go-fullscreen {
      background: var(--str-video__icon--fullscreen) center no-repeat;
      border-radius: var(--str-video__border-radius-xxs);
      cursor: pointer;
      width: 32px;
      height: 32px;

      &:hover {
        background-color: var(--str-video__overlay-color);
      }
    }
  }
}

.str-video__icon {
  background-color: var(--str-video__icon-default);
  &--blur {
    mask-image: var(--str-video__icon--blur);
    -webkit-mask-image: var(--str-video__icon--blur);
  }

  &--blur-off {
    mask-image: var(--str-video__icon--blur-off);
    -webkit-mask-image: var(--str-video__icon--blur-off);
  }
}

.sheet-trigger-chat-widget .flex-1:first-of-type {
  max-height: 100%;
}
